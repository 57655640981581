import axios from '@/libs/axios'
import axiosDef from 'axios'
const APP_CURRENCIES = process.env.VUE_APP_CURRENCYS
const VUE_APP_PRODUCTS = process.env.VUE_APP_PRODUCTS
const WALLET_TOKEN = process.env.VUE_APP_CURRENCYS_TOKEN
const APP_GATEWAY = process.env.VUE_APP_GATEWAY
const GATEWAY_TOKEN = process.env.VUE_APP_GATEWAY_TOKEN

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWhitelabels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/whitelabels`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWhitelabel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/whitelabels/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addWhitelabel(ctx, whitelabelData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/whitelabels`, whitelabelData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWhitelabel(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/whitelabels/${data._id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWhitelabelPage(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/whitelabels/${id}/page`,  data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeWhitelabel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/whitelabels/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCurrencies(ctx, clientId) {
      //const token = WALLET_TOKEN
      return new Promise((resolve, reject) => {
        axios
        .get('/wallet-internal/getClientCurrencies/' + clientId)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchBrands(ctx, clientId) {
      return new Promise((resolve, reject) => {
        axios
        .get('/available-games/listbrands/' + clientId)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchBrandsByProducts(ctx, {idClient, products}) {
      return new Promise((resolve, reject) => {
        axios
            .post('/available-games/listbrands', {idClient, products})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    /*
    fetchProducts(ctx) {
      return new Promise((resolve, reject) => {
        axiosDef
        .get(VUE_APP_PRODUCTS)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    */
    fetchProducts(ctx,clientId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/clients/availProd/${clientId}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchPaymentMethods(ctx,clientId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/available-payment-method/client/${clientId}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchTemplateSlugs(ctx) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/templates-v2/default-templates-slug`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchBanners(ctx) {
      return new Promise((resolve, reject) => {
        axiosDef
        .get('/banners')
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    updateStateWhitelabel(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/whitelabels/${data._id}/status/${data.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setWallpaper(ctx, { id, wallpaperUrl }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/whitelabels/${id}/setWallpaper`, { id, wallpaperUrl })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}




