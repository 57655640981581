// plugin js file
import moment from "moment"
const yearDropdownPlugin = function (pluginConfig) {
  var defaultConfig = {
    text: "",
    theme: "light",
    date: moment().format('DD/MM/YYYY'),
    yearStart: 100,
    yearEnd: 2,
  };

  var config = {};
  for (var key in defaultConfig) {
    config[key] =
      pluginConfig && pluginConfig[key] !== undefined
        ? pluginConfig[key]
        : defaultConfig[key];
  }

  var getYear = function (value) {
    var date = value.split("/");
    return parseInt(date[2], 10);
  };

  var currYear = new Date().getFullYear();
  var selectedYear = getYear(config.date);

  var yearDropdown = document.createElement("select");
  yearDropdown.classList.add("yearDropDownFlatPick")

  var createSelectElement = function (year) {
    var start = new Date().getFullYear() - config.yearStart;
    var end = currYear + config.yearEnd;

    for (var i = end; i >= start; i--) {
      var option = document.createElement("option");
      option.value = i;
      option.text = i;
      yearDropdown.appendChild(option);
    }
    yearDropdown.value = year;
  };

  return function (fp) {
    fp.yearSelectContainer = fp._createElement(
      "div",
      "flatpickr-year-select " + config.theme + "Theme",
      config.text
    );

    fp.yearSelectContainer.tabIndex = -1;
    createSelectElement(selectedYear);
    yearDropdown.addEventListener("change", function (evt) {
      var year = evt.target.options[evt.target.selectedIndex].value;
      fp.changeYear(parseInt(year));
    });

    fp.yearSelectContainer.append(yearDropdown);

    return {
      onReady: function onReady() {
        var name = fp.monthNav.className;
        const yearInputCollection =
          fp.calendarContainer.getElementsByClassName(name);
        const el = yearInputCollection[0];
        el.parentNode.insertBefore(
          fp.yearSelectContainer,
          el.parentNode.firstChild
        );
      },
      onMonthChange: function(selectedDates, dateStr, instance) {
        var yearDropdown = document.querySelector(".yearDropDownFlatPick");
        yearDropdown.value = instance.currentYear;
      },
    };
  };
};

export default yearDropdownPlugin;
// if (typeof module !== "undefined")
//     module.exports = yearDropdownPlugin;
