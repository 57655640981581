<template>
  <b-button
    variant="primary"
    class="d-flex align-items-center justify-content-center"
    @click="downloadFile()"
    :disabled="this.disabled"
  >
    <b-spinner v-if="buttonLoading" small class="mr-1"></b-spinner>
    <feather-icon v-else icon="DownloadIcon" size="16" class="mr-50" />
    <span class="text-nowrap">{{ $t("labels.export") }}</span>
  </b-button>
</template>

<script>
import { downloadReportFile } from "@core/utils/utils";
import { BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default {
  name: "ExportButton",
  components: { BButton },
  props: ["filter", "url", "fileTitle", "disabled"],
  data() {
    return {
      buttonLoading: false,
    };
  },
  methods: {
    async downloadFile() {
      try {
        this.buttonLoading = true;
        await downloadReportFile({
          url: this.url,
          filter: this.filter,
          fileTitle: this.fileTitle,
        });
        this.notify(this.$t('downloaded', {file: this.fileTitle}), "success");
      } catch (e) {
          console.log(e)
        this.notify(axiosErrorHandle(e), "danger");
      } finally {
        this.buttonLoading = false;
      }
    },

    notify(title, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t(title),
          icon: "DownloadIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped></style>
